import React from "react";
import logo from "../logo.svg";
import "./App.css";
// import "./index.css";
import NavBar from "./components/NavBar";
import Carousel from "./components/Carousel";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";

const slides = [
  "/SliderImage1.jpeg",
  // "/SliderImage2.png",
  "/SliderImage3.jpeg",
  "/SliderImage4.jpg",
  // "/Crawley-Station-Header.jpg",
  // "/Three-Bridges-Station-Slider.jpg",
];

function App() {
  return (
    <div>
      {/* <div className="App"> */}
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <NavBar toggleOTPModal={() => {}} />
      <Carousel slides={slides} autoSlide={true} toggleOTPModal={() => {}} />
      <Outlet />
      <Footer />
      {/* <RouterProvider router={router} /> */}
    </div>
  );
}

export default App;
