import React from "react";
import PageHeading from "../../components/PageHeading";
import Feature1 from "../../components/Feature1";
import NewsLetter from "../../components/NewsLetter";

const AboutPage = () => {
  return (
    <div>
      <PageHeading title={"About Us"} />
      <Feature1 />
      <NewsLetter />
    </div>
  );
};

export default AboutPage;
