import {
  // BriefcaseIcon,
  // CalendarIcon,
  CheckIcon,
  // ChevronDownIcon,
  // CurrencyDollarIcon,
  // LinkIcon,
  // MapPinIcon,
  // PencilIcon,
} from "@heroicons/react/20/solid";

export default function ServicesList() {
  return (
    <div className="bg-white py-2 sm:py-4">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Our Services
        </h2> */}
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-28 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <li className="list-none">
            <CheckIcon />
            <h5 className="text-center text-lg font-semibold">
              Airport Transfer
            </h5>
          </li>
          <li className="list-none">
            <CheckIcon />
            <h5 className="text-center text-lg font-semibold">
              Seaport Transfer
            </h5>
          </li>
          <li className="list-none">
            <CheckIcon />
            <h5 className="text-center text-lg font-semibold">
              Station Transfer
            </h5>
          </li>
          <li className="list-none">
            <CheckIcon />
            <h5 className="text-center text-lg font-semibold">
              University Links
            </h5>
          </li>
          <li className="list-none">
            <CheckIcon />
            <h5 className="text-center text-lg font-semibold">City Touring</h5>
          </li>
        </div>
      </div>
    </div>
  );
}
