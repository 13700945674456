import React from "react";
import Feature1 from "../../components/Feature1";
import PageHeading from "../../components/PageHeading";
import NewsLetter from "../../components/NewsLetter";
import ServicesList from "../../components/ServicesList";
import Feature2 from "../../components/Feature2";
import Service from "../../components/Service";
import benefitOneImg from "../../../../public/Crawley-Station-Header.jpg";
import {
  AdjustmentsHorizontalIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  FaceSmileIcon,
  SunIcon,
} from "@heroicons/react/24/outline";

const serviceOne = {
  title: "Airport Transfers",
  desc: "You can use this space to highlight your first benefit or a feature of your product. It can also contain an image or Illustration like in the example along with some bullet points.",
  image: "/Crawley-Station-Header.jpg",
  bullets: [
    {
      title: "Understand your customers",
      desc: "Then explain the first point breifly in one or two lines.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Improve acquisition",
      desc: "Here you can add the next benefit point.",
      icon: <ChartBarSquareIcon />,
    },
    {
      title: "Drive customer retention",
      desc: "This will be your last bullet point in this section.",
      icon: <CursorArrowRaysIcon />,
    },
  ],
};

const serviceTwo = {
  title: "Offer more benefits here",
  desc: "You can use this same layout with a flip image to highlight your rest of the benefits of your product. It can also contain an image or Illustration as above section along with some bullet points.",
  image: "/Crawley-Station-Header.jpg",
  bullets: [
    {
      title: "Mobile Responsive Template",
      desc: "Nextly is designed as a mobile first responsive template.",
      icon: <DevicePhoneMobileIcon />,
    },
    {
      title: "Powered by Next.js & TailwindCSS",
      desc: "This template is powered by latest technologies and tools.",
      icon: <AdjustmentsHorizontalIcon />,
    },
    {
      title: "Dark & Light Mode",
      desc: "Nextly comes with a zero-config light & dark mode. ",
      icon: <SunIcon />,
    },
  ],
};

const ServicesPage = () => {
  return (
    <div className="bg-white">
      <PageHeading
        title={"Our Services"}
        text={"Serving Excellence, Mile after Mile"}
      />
      <ServicesList />
      {/* <Feature1 /> */}
      <Service data={serviceOne} />
      <Service data={serviceTwo} imgPos="right" />
      <Service data={serviceOne} />
      <Service data={serviceTwo} imgPos="right" />
      <Service data={serviceOne} />
      {/* <Feature2 /> */}
      <NewsLetter />
    </div>
  );
};

export default ServicesPage;
