import React from "react";
import PageHeading from "../../components/PageHeading";
import Feature1 from "../../components/Feature1";
import MobileAppBanner from "../../components/MobileAppBanner";
import Testimonials from "../../components/Testimonials";
import NewsLetter from "../../components/NewsLetter";

const HomePage = () => {
  return (
    <div>
      <PageHeading title={"Welcome to Get A Driver service"} />
      <Feature1 />
      {/* <Feature1 /> */}
      <MobileAppBanner />
      <Testimonials />
      <NewsLetter />
    </div>
  );
};

export default HomePage;
