import {
  InformationCircleIcon,
  ClockIcon,
  ServerStackIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Why Choose Us?",
    description:
      "Get A Driver are fully licensed Minicab Service, Our prices are very competitive as we are the providers of one of the cheapest rates on the market.",
    icon: InformationCircleIcon,
  },
  {
    name: "24/7 Taxi Service:",
    description:
      "Excellent customer services department. We take any complaints or comments personally.",
    icon: ClockIcon,
  },
  {
    name: "Services:",
    description:
      "At Get A Driver we provide all Private car or Private taxi services.",
    icon: ServerStackIcon,
  },
];

export default function Feature1() {
  return (
    <div className="overflow-hidden bg-white py-4 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Who Are we
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Luxurious journeys from those who know London best - London Get
                A Driver Service
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Established since 2008, we pride ourselves on providing our
                clients with the highest level of professional service. Our
                uniformed chauffeurs are extensively trained and experienced,
                and they consistently exhibit a professional, reliable and
                friendly manner.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="/Three-Bridges-Station-Slider.jpg"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
