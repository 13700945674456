"use client";

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  // HandRaisedIcon,
  // CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";

export default function Carousel({
  autoSlide = false,
  autoSlideInterval = 3000,
  slides,
  toggleOTPModal,
}: {
  autoSlide?: boolean;
  autoSlideInterval?: number;
  slides: string[];
  toggleOTPModal: () => void;
}) {
  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval]);

  return (
    <div className="overflow-hidden relative">
      <div
        className="flex transition-transform ease-out duration-500 sm:max-h-[30rem] md:max-h-[35rem] lg:max-h-[40rem] xl:max-h-[45rem] 2xl:max-h-[50rem]"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides.map((img, id) => (
          // <img src={img} alt="" key={img} className="w-full h-auto" />
          <div
            key={id}
            className="w-full flex-shrink-0 bg-cover bg-no-repeat"
            style={{
              backgroundImage: `linear-gradient(180deg,rgba(10,10,10,0.25) 10%,rgba(10,10,10,0.25) 100%),url(${img})`,
            }}
          >
            <div className="w-full h-full">
              <div className="relative isolate overflow-hidden py-16 sm:py-24 lg:py-32">
                <div className="mx-auto px-8 lg:px-20">
                  <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-1">
                    <div className="max-w-xl lg:max-w-2xl">
                      <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Get a Driver Limited
                      </h1>
                      <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        UK Taxi Service
                      </h1>
                      <p className="mt-4 text-lg leading-8 text-white sm:text-2xl text-justify">
                        Book a cab on the go or use our online service. Compare
                        the prices, choose the best and book your cab. 24/7
                        customer support.
                      </p>
                      <h3 className="mt-4 text-l font-bold tracking-tight text-white sm:text-2xl">
                        CALL US 24/7 : 01293 978797
                      </h3>
                      <button
                        type="button"
                        className="mt-4 p-1 bg-gray-800 text-gray-300 font-bold text-l hover:bg-gray-700 hover:text-white ring-2 ring-white"
                        onClick={() => {
                          toggleOTPModal();
                        }}
                      >
                        {/* <span className="absolute -inset-1.5" /> */}
                        Book Online Now
                      </button>
                    </div>
                    {/* <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                      <div className="flex flex-col items-start">
                        <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                          <CalendarDaysIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <dt className="mt-4 font-semibold text-white">
                          Weekly articles
                        </dt>
                        <dd className="mt-2 leading-7 text-gray-400">
                          Non laboris consequat cupidatat laborum magna. Eiusmod
                          non irure cupidatat duis commodo amet.
                        </dd>
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                          <HandRaisedIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <dt className="mt-4 font-semibold text-white">
                          No spam
                        </dt>
                        <dd className="mt-2 leading-7 text-gray-400">
                          Officia excepteur ullamco ut sint duis proident non
                          adipisicing. Voluptate incididunt anim.
                        </dd>
                      </div>
                    </dl> */}
                  </div>
                </div>
                {/* <div
                  className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
                  aria-hidden="true"
                >
                  <div
                    className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                  />
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="absolute inset-0 flex items-center p-4 max-w-fit">
        <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <ArrowLeftCircleIcon />
        </button>
      </div>
      <div className="absolute inset-0 flex items-center p-4 max-w-fit ml-auto">
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <ArrowRightCircleIcon />
        </button>
      </div>

      <div className="absolute bottom-4 right-0 left-0">
        <div className="flex items-center justify-center gap-2">
          {slides.map((_, i) => (
            <div
              className={`
              transition-all w-3 h-3 bg-white rounded-full
              ${curr === i ? "p-2" : "bg-opacity-50"}
            `}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
