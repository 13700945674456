import React from "react";
import PageHeading from "../../components/PageHeading";
import Contact from "../../components/Contact";
import Stats from "../../components/Stats";
import NewsLetter from "../../components/NewsLetter";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

const stats = [
  {
    id: 1,
    name: (
      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
        <MapPinIcon
          className="h-6 w-6 text-red-600 inline"
          aria-hidden="true"
        />
      </div>
    ),
    value: "260 ifield drive Crawley RH11 0EP",
  },
  {
    id: 2,
    name: (
      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
        <DevicePhoneMobileIcon
          className="h-6 w-6 text-red-600 inline"
          aria-hidden="true"
        />
      </div>
    ),
    value: "01293 978797",
  },
  {
    id: 3,
    name: (
      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
        <EnvelopeIcon
          className="h-6 w-6 text-red-600 inline"
          aria-hidden="true"
        />
      </div>
    ),
    value: "contact@getadriver.co.uk",
  },
];

const ContactPage = () => {
  return (
    <>
      {/* <PageHeading title={"Contact Us"} /> */}
      <Contact />
      <Stats stats={stats} />
      <NewsLetter />
    </>
  );
};

export default ContactPage;
