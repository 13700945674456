import React from "react";
// import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/page";
import ServicesPage from "../pages/services/page";
import AboutPage from "../pages/about/page";
import ContactPage from "../pages/contact/page";
import TermsAndConditions from "../pages/terms/page";
import App from "../App";

const routes = [
  {
    // path: "/",
    element: <App />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/services", element: <ServicesPage /> },
      { path: "/about", element: <AboutPage /> },
      { path: "/contact", element: <ContactPage /> },
      { path: "/terms", element: <TermsAndConditions /> },
    ],
  },
];

export default routes;
